<template>
	<div>
		<!-- :class="isRevert ? 'work-card-revert' : 'work-card'" -->
		<div
			v-if="workCard"
			class="work-card"
			@mouseover="isHover = true"
			@mouseleave="isHover = false"
		>
			<!-- <router-link :to="`/project`"> -->
			<router-link :to="`${workCard.slug}`">
				<!-- <a href="#"> -->
				<figure class="work-img">
					<img :src="workCard.path" alt="" />
					<!-- <img src="@/assets/images/work-card-1.png" alt="" /> -->
					<!-- <img src="@/assets/images/work-card-1.png" alt="" /> -->
				</figure>
				<div class="work-title">
					<div v-if="isHover" class="flex items-center justify-center">
						<span class="inline-block mx-2">{{ $t("common.go") }}</span>
						<font-awesome-icon
							:icon="
								$i18n.locale === 'ar'
									? ['fas', 'arrow-left']
									: ['fas', 'arrow-right']
							"
							size="1x"
						/>
					</div>
					<p v-else class="">{{ workCard.name }}</p>
				</div>
				<!-- </a> -->
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	// name: "HelloWorld",
	props: ["workCard"],
	data() {
		return {
			card: null,
			isHover: false
		};
	},
	mounted() {
		// this.card = this.workCard;
		// console.log(this.workCard);
	}
};
</script>
