<template>
	<div class="page--work mx-auto">
		<div class="container flex flex-col mx-auto">
			<section class="mt-4 2xs:mt-10 xs:mt-20">
				<div class="work-show">
					<ul class="work-nav flex flex-wrap justify-start pl-8 xs:pl-0">
						<li
							class="work-nav-item work-nav-specific px-2 xs:px-6 whitespace-nowrap"
							id="all-work"
						>
							<button @click="changeCards(null)">
								{{ $t("common.allWork") }}
							</button>
						</li>
						<li
							v-for="type in types"
							:key="type.key"
							:id="`${type.key}`"
							class="work-nav-item work-nav-specific px-2 xs:px-6 whitespace-nowrap"
						>
							<button @click="changeCards(type.key)">{{ type.name }}</button>
						</li>
					</ul>
				</div>
			</section>
			<div class="work-items" v-if="filterWorkCards.length">
				<div class="flex flex-wrap work-item">
					<div
						v-for="(card, index) in filterWorkCards"
						:key="index"
						class="w-full xs:w-1/2 lg:w-1/3 p-8 work-card-external"
					>
						<work-card :work-card="card" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import WorkCard from "@/components/WorkCard";

export default {
	props: ["workCards", "types"],
	components: {
		WorkCard
	},
	mounted() {
		// console.log(this.workCards);
		// if (this.web) {
		// 	console.log("web");
		// 	this.filterWorkCards = this.workCards.filter(card => card.type === "web");
		// } else if (this.phone) {
		// 	console.log("phone");
		// 	this.filterWorkCards = this.workCards.filter(
		// 		card => card.type === "phone"
		// 	);
		// } else if (this.other) {
		// 	console.log("other");
		// 	this.filterWorkCards = this.workCards.filter(
		// 		card => card.type === "other"
		// 	);
		// } else {
		// 	console.log("all");
		// 	console.log(this.web);
		// 	this.filterWorkCards = this.workCards;
		// }
		// if (this.type) {
		// 	console.log(this.type);
		// 	this.filterWorkCards = this.workCards.filter(
		// 		card => card.type === this.type
		// 	);
		// } else {
		// 	console.log("all");
		// 	console.log(this.type);
		// 	this.filterWorkCards = this.workCards;
		// }
		// this.filterWorkCards = this.workCards;
		// document.getElementById(`all-work`).classList.add("active-work");

		// this.types = this.workCards
		// 	.map(card => card.type)
		// 	.reduce((x, y) => (x.includes(y) ? x : [...x, y]), []);
		// this.filterWorkCards = this.workCards.filter(
		// 	card => card.type === this.types[0]
		// );
		// console.log(this.types);
		// console.log(this.filterWorkCards);
		// console.log(this.workCards);
		this.changeCards();
	},
	data() {
		return {
			// workCards: [
			//     {
			//         name: "card1",
			//         path: "@/assets/images/fastanswer1.png",
			//         type: "mobile_app"
			//     },
			//     {
			//         name: "card1",
			//         path: "@/assets/images/fastanswer1.png",
			//         type: "mobile_app"
			//     },
			//     {
			//         name: "card1",
			//         path: "@/assets/images/fastanswer1.png",
			//         type: "mobile_app"
			//     },
			//     {
			//         name: "card1",
			//         path: "@/assets/images/fastanswer1.png",
			//         type: "web_site"
			//     },
			//     {
			//         name: "card1",
			//         path: "@/assets/images/fastanswer1.png",
			//         type: "web_site"
			//     },
			//     {
			//         name: "card1",
			//         path: "@/assets/images/fastanswer1.png",
			//         type: "web_site"
			//     },
			//     {
			//         name: "card1",
			//         path: "@/assets/images/fastanswer1.png",
			//         type: "other"
			//     },
			//     {
			//         name: "card1",
			//         path: "@/assets/images/fastanswer1.png",
			//         type: "mobile_app"
			//     },
			//     {
			//         name: "card1",
			//         path: "@/assets/images/fastanswer1.png",
			//         type: "mobile_app"
			//     },
			//     {
			//         name: "card1",
			//         path: "@/assets/images/fastanswer1.png",
			//         type: "other"
			//     }
			// ],
			// types: [
			//     { name: "web", key: "web_site" },
			//     { name: "mobile", key: "mobile_app" },
			//     { name: "other", key: "other" }
			// ],
			filterWorkCards: []
		};
	},
	methods: {
		changeCards(type) {
			this.filterWorkCards = [];
			if (type) {
				console.log(type);
				this.filterWorkCards = this.workCards.filter(
					card => card.type === type
				);
				const elements = document.querySelectorAll(`.work-nav-specific`);
				for (let i = 0; i < elements.length; i++) {
					if (elements[i].classList.contains("active-work")) {
						// console.log(elements[i]);
						elements[i].classList.remove("active-work");
					}
					// console.log(elements[i]);
				}
				document.getElementById(`all-work`).classList.remove("active-work");
				document.getElementById(`${type}`).classList.add("active-work");
			} else {
				console.log("all");
				this.filterWorkCards = this.workCards;
				const elements = document.querySelectorAll(`.work-nav-specific`);
				for (let i = 0; i < elements.length; i++) {
					if (elements[i].classList.contains("active-work")) {
						console.log(elements[i]);
						elements[i].classList.remove("active-work");
					}
				}
				// document.getElementById(`all-work`).classList.remove("active-work");
				document.getElementById(`all-work`).classList.add("active-work");
			}
			console.log(this.filterWorkCards);
		}
	}
};
</script>
