<template>
	<div class="page--work flex flex-col mx-auto">
		<div class="flex flex-col mx-auto container container-l">
			<section v-if="firstProject">
				<div class="w-full">
					<div class="recent-work text-center">
						<h1 class="text-2xl xs:text-4xl lg:text-7xl font-black">
							{{ $t("headings.recentProject") }}
						</h1>
						<!-- <router-link :to="`/project`"> -->
						<router-link :to="`${firstProject.slug}`">
							<figure
								class="recent-work-img shadow-full overflow-hidden mt-4 xs:mt-8 mx-3"
							>
								<!-- <img
									class="w-full h-full object-cover"
									src="@/assets/images/recent-work.png"
									alt=""
								/> -->
								<img
									class="w-full h-full object-cover"
									:src="firstProject.path"
									alt=""
								/>
							</figure>
						</router-link>
					</div>
				</div>
			</section>
			<!-- <section class="mt-20">
			<div class="work-show">
				<ul class="work-nav flex justify-start">
					<li class="work-nav-item mx-12">
						<button @click="type = null">All work</button>
					</li>
					<li class="work-nav-item mx-12">
						<button @click="type = 'web'">website</button>
					</li>
					<li class="work-nav-item mx-12">
						<button @click="type = 'phone'">mobile app</button>
					</li>
					<li class="work-nav-item mx-12">
						<button @click="type = 'other'">other work</button>
					</li>
				</ul>
			</div>
		</section> -->
			<section>
				<div class="work-items">
					<!-- <div class="work-item"> -->
					<!-- <div class="flex flex-wrap justify-between work-item"> -->
					<!-- <div
						v-for="(card, index) in workCards"
						:key="index"
						class="w-1/3 p-8 work-card-external"
					>
						<work-card :work-card="card" />
					</div> -->
					<!-- <router-view /> -->
					<!-- <div v-if="type">other <work-section :type="other" /></div> -->
					<!-- <div v-else-if="(type = 'web')">web<work-section type="web" /></div>
				<div v-else-if="(type = 'phone')">
					phone<work-section type="phone" />
				</div> -->
					<!-- <div v-else>allll <work-section /></div> -->
					<work-section
						v-if="workCardsShift.length"
						:work-cards="workCardsShift"
						:types="types"
					/>
					<!-- <team-card /> -->
				</div>
				<!-- </div> -->
			</section>
		</div>
	</div>
</template>
<script>
// import TeamCard from "@/components/itemscomponents/TeamCard.vue";
// import WorkCard from "@/components/itemscomponents/WorkCard";
import WorkSection from "../components/WorkSection.vue";

export default {
	components: {
		// WorkCard
		WorkSection
		// TeamCard
	},
	data() {
		return {
			workCards: [
				{
					name: "Gabal Equepment",
					path: "images/gabal-cover.png",
					type: "web_site",
					slug: "/gabal-equepment"
				},
				{
					name: "7fza",
					path: "images/7fza-main.png",
					type: "web_site",
					slug: "/7fza"
				},
				{
					name: "Alf Hana",
					path: "images/alfhana-main.png",
					type: "mobile_app",
					slug: "/alf-hana"
				},
				{
					name: "Eletihad Factory",
					path: "images/factory-main.png",
					type: "web_site",
					slug: "/eletihad-factory"
				}
				// {
				// 	name: "card1",
				// 	path: "images/factory-main.png",
				// 	type: "web_site"
				// },
				// {
				// 	name: "card1",
				// 	path: "images/factory-main.png",
				// 	type: "web_site"
				// },
				// {
				// 	name: "card1",
				// 	path: "images/factory-main.png",
				// 	type: "other"
				// },
				// {
				// 	name: "card1",
				// 	path: "images/factory-main.png",
				// 	type: "mobile_app"
				// },
				// {
				// 	name: "card1",
				// 	path: "images/factory-main.png",
				// 	type: "mobile_app"
				// },
				// {
				// 	name: "card1",
				// 	path: "images/factory-main.png",
				// 	type: "other"
				// }
			],
			types: [
				{ name: "web", key: "web_site" },
				{ name: "mobile", key: "mobile_app" }
				// { name: "other", key: "other" }
			],
			firstProject: null,
			workCardsShift: []
		};
	},
	mounted() {
		// console.log(this.workCards);
		this.firstProject = this.workCards.shift();
		this.workCardsShift = this.workCards;
		// console.log(this.workCardsShift);
		// console.log(this.workCards);
		// console.log(this.firstProject);
	}
};
</script>
